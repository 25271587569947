import type { ProjectSchema } from '@readme/backend/models/project/types';

/** Combined view of both plan details and features. */
export type PlanConfig = PlanDetails & PlanFeatures;
/** Union of all plan config keys. */
export type PlanConfigKey = keyof PlanConfig;
/** Union of all plan feature keys. */
export type PlanFeatureKey = keyof PlanFeatures;

/**
 * General plan information like cost, beginning/end date, name, etc.
 * */
export interface PlanDetails {
  /** Total cost (in USD) for this plan. */
  cost: number;
  /** When this plan was started. */
  createdAt: Date;
  /** When this plan will end. */
  endDate?: Date;
  /** Whether plan is currently activated or not. */
  is_active: boolean;
  /** What is the maximum amount of admins that this plan allows? */
  members: number;
  /** Plan name, e.g. `Startup`, `Business`. */
  name: string;
}

/**
 * Plan features, which are mostly `boolean` types that indicates whether a plan
 * supports this particular feature or not.
 */
export interface PlanFeatures {
  /**
   * Can this plan have users classified as a project admin?
   */
  admins: boolean;

  /** Does this plan have access to our API? */
  api_access: boolean;

  /** Does this plan have access to changelogs? */
  changelogs: boolean;

  /** Does this plan have access to set a custom domain? */
  custom_domain: boolean;

  /** Does this plan have access to custom pages? */
  custom_pages: boolean;

  /** Can this plan access discussion threads with users? */
  discussions: boolean;

  /** Can this plan access and view doc metrics? */
  doc_metrics: boolean;

  /**
   * Can this plan configure custom 404 error pages?
   */
  errors: boolean;

  /** Can this plan set custom HTML in their header and footer? */
  extra_html: boolean;

  /** Can this plan use glossaries? */
  glossary: boolean;

  /** Can this plan create guides? */
  guides: boolean;

  /** Can this plan set up an integration with a health check service? */
  healthCheck: boolean;

  /** Can this plan set custom HTML in their landing page? */
  html: boolean;

  /** Can this plan have custom JS? */
  javascript: boolean;
  jwt: boolean;

  /** Can this plan create a global landing page (GLP)? */
  landing_page: boolean;

  /** Can this plan export metrics (CSV downloads)? */
  metricsExports: boolean;

  /** Can this plan export owlbot questions (CSV downloads)? */
  owlbotExports: boolean;

  /** Can this plan password-protect their Hub? */
  password: boolean;

  /** Can this plan export pages as a PDF? */
  pdf: boolean;

  /** Can this plan access and create recipes? */
  recipes: boolean;

  /** Can this plan upload OpenAPI definitions? */
  reference: boolean;

  /** Can this plan create and update reusable content? */
  reusableContent: boolean;

  /** Can this plan have custom CSS? */
  stylesheet: boolean;

  /** Can this plan customize site navigation? */
  usability: boolean;

  /** Can this plan interact with backend services that use Clearbit for user introspection? */
  user_analytics: boolean;

  /** Does this plan have access to user variables? */
  variables: boolean;

  /** The number of versions that this plan can hold. */
  versions: number;

  /** Can this plan hide the ReadMe branding in the hub footer? */
  whitelabel: boolean;
}

/**
 * IF YOU MAKE CHANGES TO THIS CONFIG MAKE SURE TO UPDATE BOTH THE LEGACY CONFIGS TOO.
 *
 * @see @readme/legacy-angular-tooling/shared.js
 * @see @readme/server-shared/shared.js
 */
const basePlans = {
  free: {
    // NOTE: If we add this tier back in, make sure we only
    // allow trial accounts to have teammates (see getAllRegularProjects)
    name: 'Inactive Project',
    members: 0,
    is_active: true,
    cost: 0,
    createdAt: new Date('January 1, 2010'),
    endDate: undefined,

    versions: 1,
    api_access: true,
    custom_domain: false,
    custom_pages: false,
    whitelabel: false,
    errors: false,
    password: false,
    glossary: false,
    landing_page: false,
    pdf: false,

    usability: false,
    stylesheet: false,
    javascript: false,
    html: false,
    extra_html: false,
    user_analytics: false,
    doc_metrics: true,
    variables: false,
    jwt: true,

    changelogs: false,
    reference: true,
    guides: false,
    recipes: false,
    discussions: false,

    admins: false,
    healthCheck: false,

    metricsExports: false,
    owlbotExports: false,
    reusableContent: false,
  },
  startup: {
    name: 'Startup',
    members: 10,
    is_active: true,
    cost: 59,
    createdAt: new Date('January 1, 2010'),
    endDate: new Date('August 28, 2018'),

    versions: 10000,
    api_access: true,
    custom_domain: true,
    custom_pages: true,
    whitelabel: false,
    errors: false,
    password: true,
    glossary: true,
    landing_page: true,
    pdf: false,

    usability: true,
    stylesheet: true,
    javascript: false,
    html: true,
    extra_html: false,
    user_analytics: false,
    doc_metrics: true,
    variables: true,
    jwt: true,

    changelogs: true,
    reference: true,
    guides: true,
    recipes: true,
    discussions: true,

    admins: true,
    healthCheck: false,

    metricsExports: false,
    owlbotExports: false,
    reusableContent: false,
  },
  business: {
    name: 'Business',
    is_active: true,
    members: 1000,
    cost: 199,
    createdAt: new Date('January 1, 2010'),
    endDate: new Date('August 28, 2018'),

    versions: 10000,
    api_access: true,
    custom_domain: true,
    custom_pages: true,
    whitelabel: true,
    errors: true,
    password: true,
    glossary: true,
    landing_page: true,
    pdf: true,

    usability: true,
    stylesheet: true,
    javascript: true,
    html: true,
    extra_html: true,
    user_analytics: true,
    doc_metrics: true,
    variables: true,
    jwt: true,

    changelogs: true,
    reference: true,
    guides: true,
    recipes: true,
    discussions: true,

    admins: true,
    healthCheck: true,

    metricsExports: true,
    owlbotExports: true,
    reusableContent: true,
  },
} as const satisfies Record<PLAN_NAME.BUSINESS | PLAN_NAME.FREE | PLAN_NAME.STARTUP, PlanConfig>;

/**
 * IF YOU MAKE CHANGES TO THIS CONFIG MAKE SURE TO UPDATE BOTH THE LEGACY CONFIGS TOO.
 *
 * @see `@readme/legacy-angular-tooling/shared.js`
 * @see `@readme/server-shared/shared.js`
 */
const permissions = {
  ...basePlans,

  freelaunch: {
    ...basePlans.free,
    admins: true,
    cost: 0,
    createdAt: new Date('May 4, 2020'),
    endDate: undefined,
    members: 5,
    name: 'Free',
    reusableContent: false,
    stylesheet: false,
    versions: 3,
  },

  startup2018: {
    ...basePlans.startup,
    cost: 99,
    createdAt: new Date('August 28, 2018'),
    endDate: undefined,
    stylesheet: false,
  },

  'startup-annual-2024': {
    ...basePlans.startup,
    cost: 948,
    createdAt: new Date('March 27, 2024'),
    endDate: undefined,
    stylesheet: false,
  },

  business2018: {
    ...basePlans.business,
    cost: 399,
    createdAt: new Date('August 28, 2018'),
    endDate: undefined,
    javascript: false,
    metricsExports: true,
    owlbotExports: true,
  },

  'business-annual-2024': {
    ...basePlans.business,
    cost: 4188,
    createdAt: new Date('March 27, 2024'),
    endDate: undefined,
    javascript: false,
    metricsExports: true,
    owlbotExports: true,
  },

  enterprise: {
    ...basePlans.business,
    cost: 0,
    createdAt: new Date('January 1, 2010'),
    endDate: undefined,
    javascript: true,
    members: 10000,
    metricsExports: true,
    name: 'Enterprise',
    owlbotExports: true,
  },

  opensource: {
    ...basePlans.startup,
    cost: 0,
    createdAt: new Date('January 1, 2010'),
    custom_domain: false,
    endDate: undefined,
    is_active: true,
    name: 'Open Source',
    password: false,
  },
} as const satisfies Record<PLAN_NAME, PlanConfig>;

export enum StripePlanInterval {
  DAY = 'day',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
}

export enum PLAN_NAME {
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  ENTERPRISE = 'enterprise',
  FREE = 'free', // trial
  FREELAUNCH = 'freelaunch', // free post-trial period
  OPENSOURCE = 'opensource',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
}

export enum PlanCadence {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const PlanCadenceArray: PlanCadence[] = Object.values(PlanCadence);

export enum PlanType {
  FREE = 0 /* freelaunch */,
  TRIAL = 1 /* free */,
  STARTUP = 2 /* startup (legacy) or startup2018 or startup-annual-2024 or opensource */,
  BUSINESS = 3 /* business (legacy) or business2018 or business-annual-2024 */,
  ENTERPRISE = 4 /* enterprise */,
  DEV_DASH = 5 /* dev dash metrics metered billing */,
}

export const planNameToType: Record<PLAN_NAME, PlanType> = {
  [PLAN_NAME.FREELAUNCH]: PlanType.FREE,
  [PLAN_NAME.FREE]: PlanType.TRIAL,
  [PLAN_NAME.OPENSOURCE]: PlanType.STARTUP,
  [PLAN_NAME.STARTUP]: PlanType.STARTUP,
  [PLAN_NAME.STARTUP_2018]: PlanType.STARTUP,
  [PLAN_NAME.BUSINESS]: PlanType.BUSINESS,
  [PLAN_NAME.BUSINESS_2018]: PlanType.BUSINESS,
  [PLAN_NAME.ENTERPRISE]: PlanType.ENTERPRISE,
  [PLAN_NAME.STARTUP_ANNUAL_2024]: PlanType.STARTUP,
  [PLAN_NAME.BUSINESS_ANNUAL_2024]: PlanType.BUSINESS,
};

export const planNameToCadence: Record<PLAN_NAME, PlanCadence> & { '': PlanCadence.MONTHLY } = {
  '': PlanCadence.MONTHLY, // Default to monthly
  [PLAN_NAME.FREELAUNCH]: PlanCadence.MONTHLY,
  [PLAN_NAME.FREE]: PlanCadence.MONTHLY,
  [PLAN_NAME.OPENSOURCE]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP_2018]: PlanCadence.MONTHLY,
  [PLAN_NAME.BUSINESS]: PlanCadence.MONTHLY,
  [PLAN_NAME.BUSINESS_2018]: PlanCadence.MONTHLY,
  [PLAN_NAME.ENTERPRISE]: PlanCadence.MONTHLY,
  [PLAN_NAME.STARTUP_ANNUAL_2024]: PlanCadence.YEARLY,
  [PLAN_NAME.BUSINESS_ANNUAL_2024]: PlanCadence.YEARLY,
};

export const PlanNameArray: PLAN_NAME[] = Object.values(PLAN_NAME);

export enum ADD_ONS {
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  METRICS = 'metrics',
  OWLBOT = 'owlbot',
}

/* eslint-disable perfectionist/sort-enums */
export enum PLANS_AND_ADD_ONS {
  // project plans
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  ENTERPRISE = 'enterprise',
  FREE = 'free',
  // trial
  FREELAUNCH = 'freelaunch',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
  // add on plans
  METRICS = 'metrics', // old metrics plan (pre-2024, not metered)
  // free post-trial period
  OPENSOURCE = 'opensource',
  OWLBOT = 'owlbot',
}
/* eslint-enable perfectionist/sort-enums */

/* eslint-disable perfectionist/sort-enums */
export enum STRIPE_PLANS {
  // project plans
  BUSINESS = 'business',
  BUSINESS_2018 = 'business2018',
  BUSINESS_ANNUAL_2024 = 'business-annual-2024',
  DEV_DASH_METERED_2024 = 'dev-dash-metered-2024',
  FREE = 'free',
  // trial
  FREELAUNCH = 'freelaunch',
  STARTUP = 'startup',
  STARTUP_2018 = 'startup2018',
  STARTUP_ANNUAL_2024 = 'startup-annual-2024',
  METRICS = 'metrics', // old metrics plan (pre-2024, not metered)
  OWLBOT = 'owlbot',
}
/* eslint-enable perfectionist/sort-enums */

/**
 * Retrieve the config for a specific plan feature.
 */
export function getPlanFeature<
  Plan extends `${PLAN_NAME}` | undefined = undefined,
  Key extends PlanConfigKey | undefined = undefined,
  Value = Plan extends `${PLAN_NAME}` ? (Key extends PlanConfigKey ? PlanConfig[Key] : PlanConfig) : undefined,
>(plan?: Plan, key?: Key) {
  return (plan && key ? permissions[plan][key] : plan ? permissions[plan] : undefined) as Value;
}

/**
 * Return the plan in which you must upgrade to in order to access a specific feature.
 */
export function getFeaturePlan(feature: PlanFeatureKey) {
  // Create a set of all currently available plans, excluding any of our legacy plans.
  const availablePlans = [PLAN_NAME.FREELAUNCH, PLAN_NAME.STARTUP_2018, PLAN_NAME.BUSINESS_2018, PLAN_NAME.ENTERPRISE];
  return availablePlans.find(plan => permissions[plan][feature]) as PLAN_NAME;
}

export function getProjectPlan(project: ProjectSchema): PLAN_NAME {
  if (project.flags?.enterprise) return PLAN_NAME.ENTERPRISE;
  if (project.planOverride) return project.planOverride;
  // for delinquent projects, we do this to avoid their hubs going offline
  if (project.planStatus === 'unpaid' || (!project.is_active && (!project.plan || project.plan === 'free')))
    return PLAN_NAME.BUSINESS_2018;
  return project.plan;
}

function getPermission(plan?: `${PLAN_NAME}`, permission?: PlanConfigKey) {
  if (plan && permission) {
    return permissions[plan][permission];
  } else if (plan) {
    return permissions[plan];
  }

  return permissions;
}

export function getProjectPermission<
  Key extends PlanConfigKey | undefined = undefined,
  Value = Key extends PlanConfigKey ? PlanConfig[Key] : PlanConfig,
>(project: ProjectSchema, permission?: Key): Value {
  if (!permission) {
    return getPermission(getProjectPlan(project)) as Value;
  }
  return getPermission(getProjectPlan(project), permission) as Value;
}
