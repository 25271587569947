import type { ProjectState } from '.';

import { PLAN_NAME } from '@readme/iso';

/**
 * Our initial `ProjectStore` state. On the Hub, this state is immediately
 * overridden by SSR props and replaced before any components connect to it.
 * This initial state simply provides us with some starting data during testing
 * and when running Styleguidist.
 */
const initialProjectState: ProjectState = {
  allow_crawlers: 'enabled',
  api_designer: {
    allow_editing: 'enabled',
  },
  appearance: {
    brand: {
      primary_color: '#333388',
      link_color: '#333388',
      theme: 'system',
    },
    changelog: {
      layout: 'collapsed',
      show_author: true,
      show_exact_date: false,
    },
    custom_code: {
      css: null,
      js: null,
      html: {
        header: null,
        home_footer: null,
        page_footer: null,
      },
    },
    footer: {
      readme_logo: 'show',
    },
    header: {
      type: 'gradient',
      gradient_color: null,
      overlay: {
        image: {
          uri: null,
          url: 'https://files.readme.io/9f73700-readme-blue.svg',
          name: 'logo-dark.png',
          width: 60,
          height: 60,
          color: '#018EF5',
          links: {
            original_url: null,
          },
        },
        type: 'grain',
        fill: 'cover',
        position: 'center-center',
      },
    },
    logo: {
      dark_mode: {
        uri: null,
        url: 'https://files.readme.io/9f73700-readme-blue.svg',
        name: 'logo-dark.png',
        width: 60,
        height: 60,
        color: '#018EF5',
        links: {
          original_url: null,
        },
      },
      main: {
        uri: null,
        url: 'https://files.readme.io/9f73700-readme-blue.svg',
        name: 'logo.png',
        width: 60,
        height: 60,
        color: '#018EF5',
        links: {
          original_url: null,
        },
      },
      favicon: {
        uri: null,
        url: 'https://files.readme.io/9f73700-readme-blue.svg',
        name: 'logo.ico',
        width: 32,
        height: 32,
        color: '#018EF5',
        links: {
          original_url: null,
        },
      },
      size: 'default',
    },
    markdown: {
      callouts: {
        icon_font: 'emojis',
      },
    },
    navigation: {
      first_page: 'landing_page',
      left: [{ type: 'home', title: null, url: null, custom_page: null }],
      links: {
        changelog: {
          label: 'Changelog',
          alias: null,
          visibility: 'enabled',
        },
        discussions: {
          label: 'Discussions',
          alias: null,
          visibility: 'enabled',
        },
        home: {
          label: 'Home',
          visibility: 'enabled',
        },
        graphql: {
          label: 'GraphQL',
          visibility: 'disabled',
        },
        guides: {
          label: 'Guides',
          alias: null,
          visibility: 'enabled',
        },
        reference: {
          label: 'API Reference',
          alias: null,
          visibility: 'enabled',
        },
        recipes: {
          label: 'Recipes',
          alias: null,
          visibility: 'enabled',
        },
      },
      logo_link: 'homepage',
      right: [],
      sub_nav: [],
      subheader_layout: 'links',
      version: 'enabled',
    },
    table_of_contents: 'enabled',
    whats_next_label: null,
  },
  canonical_url: null,
  custom_login: {
    jwt_secret: 'a-random-jwt-secret',
    login_url: null,
    logout_url: null,
  },
  default_version: { name: '1.0' },
  description: 'This is a description of my project.',
  glossary: [
    {
      definition: "Owls are generally solitary, but when seen together the group is called a 'parliament'!",
      term: 'parliament',
    },
  ],
  features: {
    mdx: 'disabled',
  },
  git: {
    connection: { repository: null, status: 'none' },
  },
  health_check: {
    provider: 'manual',
    settings: {
      manual: {
        status: 'up',
        url: 'https://custom-bird.readme.com',
      },
      statuspage: { id: null },
    },
  },
  homepage_url: null,
  id: 'a-mongo-project-id',
  integrations: {
    aws: {
      readme_webhook_login: {
        region: null,
        external_id: null,
        role_arn: null,
        usage_plan_id: null,
      },
    },
    bing: { verify: null },
    google: { analytics: null, site_verification: null },
    heap: { id: null },
    intercom: { app_id: null, secure_mode: { key: null, email_only: false } },
    koala: { key: null },
    localize: { key: null },
    recaptcha: { site_key: null, secret_key: null },
    segment: { key: null, domain: null },
    typekit: { key: null },
    zendesk: { subdomain: null },
  },
  onboarding_completed: {
    api: false,
    appearance: false,
    documentation: false,
    domain: false,
    jwt: false,
    logs: false,
    metricsSDK: false,
  },
  pages: {
    not_found: null,
  },
  parent: null,
  plan: {
    type: PLAN_NAME.BUSINESS,
    grace_period: {
      enabled: false,
      end_date: null,
    },
    trial: {
      expired: true,
      end_date: 'November 10, 2022 03:24:00',
    },
  },
  permissions: {
    appearance: {
      private_label: 'disabled',
      custom_code: {
        css: 'enabled',
        html: 'enabled',
        js: 'enabled',
      },
    },
  },
  privacy: {
    view: 'public',
    password: null,
  },
  name: 'Mocking Bird',
  redirects: [],
  refactored: {},
  reference: {
    api_sdk_snippets: 'enabled',
    defaults: 'always_use',
    json_editor: 'disabled',
    oauth_flows: 'disabled',
    request_history: 'enabled',
    response_examples: 'collapsed',
    response_schemas: 'collapsed',
  },
  seo: {
    overwrite_title_tag: 'disabled',
  },
  sitemap: 'enabled',
  subdomain: 'mocking-bird',
  suggested_edits: 'enabled',
  uri: '/projects/90210',
};

export default initialProjectState;
